import React, {Component, Suspense} from "react";
import {BrowserRouter} from "react-router-dom";
import {connect} from "react-redux";
import Loadable from "react-loadable";
import {routes} from "./routes";

// Themes
import "./assets/scss/DefaultTheme.scss";

// Lazy loading
const loading = () => <div> </div>;


const MainLayout = Loadable({
  loader: () => import("./pages/PageContainer"),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading
});


const withLayout = (WrappedComponent) => {
  const HOC = class extends Component {
    render() {
      return (<WrappedComponent {...this.props} {...this.routeProps} />);
    }
  };
  return connect()(HOC);
};

class App extends Component {
  //TODO:renders component based on authentication/ roles
  getLayout = (roles) => {
    return MainLayout;
  }

  render() {
    return (
            // rendering the router with layout

            <BrowserRouter>
              <React.Fragment>
                {routes.map((route, index) => {
                  return (
                          <route.route
                                  params={route.params}
                                  key={index}
                                  path={route.path}
                                  exact={route.exact}
                                  roles={route.roles}
                                  apiAction={route.apiAction}
                                  type={route.type}
                                  component={withLayout(props => {
                                    const Layout = this.getLayout(route.roles);
                                    return (
                                            <Suspense fallback={loading()}>
                                              <Layout {...props} title={route.title}>
                                                <route.component {...props} apiAction={route.apiAction} type={route.type} func={route.func} />
                                              </Layout>
                                            </Suspense>

                                    );
                                  })}
                          />
                  );
                })}
              </React.Fragment>
            </BrowserRouter>

    );
  }
}
export default connect()(App);
