import React from "react";
import {Redirect, Route} from "react-router-dom";


// Declare new component here
// main page
const LandingPage = React.lazy(() => import('./pages/LandingPage'));

//products
const Products = React.lazy(() => import('./pages/Products'));


//Add a new routes here if there's new page created
const routes = [
 {
  path: "/",
  exact: true,
  component: LandingPage,
  route: Route
 },
 {
    path: "/products",
    exact: true,
    component: Products,
    route: Route
   },

 ];

export {routes};
