import {combineReducers} from "redux";

const allReducers = (asyncReducers) => combineReducers({
    ...asyncReducers,
});


const rootReducer = () => allReducers();


export default rootReducer;
