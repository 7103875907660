import {applyMiddleware, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import {createLogger} from "redux-logger";
import rootReducer from "../reducers/index";

const loggerMiddleware = createLogger();
const initialState = {};


let middleware = [thunkMiddleware];
middleware = [...middleware, loggerMiddleware];


const configureStore = () => ({
    ...createStore(
            rootReducer(),
            initialState,
            applyMiddleware(...middleware),
    ),
});
export default configureStore;
